import React from "react";
import { conLinkKey, prefKey, videoKey } from "../utils/constant";

const AllMethodsLogics = () => {
  const goToPreviousTab = (currentTab, setCurrentTab) => {
    if (currentTab === videoKey) {
      return;
    } else if (currentTab === prefKey) {
      setCurrentTab(videoKey);
    } else if (currentTab === conLinkKey) {
      setCurrentTab(prefKey);
    }
  };
  const goToNextStep = (currentTab, setCurrentTab) => {
    if (currentTab === videoKey) {
      setCurrentTab(prefKey);
    } else if (currentTab === prefKey) {
      setCurrentTab(conLinkKey);
    } else if (currentTab === conLinkKey) {
      return;
    }
  };
  const greetingMsgUserName = (name) => {
    return `Hello ${name},`;
  };
  return { goToPreviousTab, goToNextStep, greetingMsgUserName };
};

export default AllMethodsLogics;
