import ConnectLinkedinBody from "../component/ConnectLinkedinBody";
import PreferencesBody from "../component/PreferencesBody";
import VideoOnBoardingBody from "../component/VideoOnBoardingBody";

export const videoKey = "vid",
  prefKey = "pref",
  conLinkKey = "conLink";

export const backButtonText = "Back",
  backButtonVariantText = "primary",
  setUpLaterButtonText = "Set up later",
  nextStepButtonText = "Next Step",
  nextStepButtonDisabledVariant = "disabled",
  nextStepButtonEnabledVariant = "primary";

export const welcomeText = "Welcome to ReachifyMe";

export const videoOnBoardingPageTabData = [
  { key: videoKey, value: <VideoOnBoardingBody /> },
  { key: prefKey, value: <PreferencesBody /> },
  { key: conLinkKey, value: <ConnectLinkedinBody /> },
];

export const videoOnBoardingDesktopUrl = `${process.env.REACT_APP_BASE_CDN_URL}/assets/video/onboarding/reachifyme-onboarding-desktop.mp4`;
export const videoOnBoardingMobileUrl = `${process.env.REACT_APP_BASE_CDN_URL}/assets/video/onboarding/reachifyme-onboarding-mobile.mp4`;
