import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import ProfilePicture from "../appBars/components/ProfilePicture";
import { getImageUrl } from "../../utils/GetImageUrl";
import { useSelector } from "react-redux";

const LinkedInConnectedModal = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  const [modalOpen, setModalOpen] = useState(true);
  const linkedInData = useSelector(
    (state) => state.linkedinConnect.linkedinConnectData[0] ?? ""
  );
  const linkedInConnected = useSelector((state) => state.linkedinConnect);
  const userData = useSelector((state) => state.userData);
  const cdnUrl = useSelector((state) => state.storage?.cdnUrl);
  const profilePicturePath =
    linkedInData?.profilePicturePath ??
    userData?.userAttributes?.profileImagePath;

  useEffect(() => {
    console.log("linkedInConnected", linkedInConnected);
    const timerInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          window.location.href = "/";
          localStorage.setItem("isLinkedinConnectedModalClosed", "true");
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    if (countdown <= 1) {
      localStorage.setItem("isLinkedinConnectedModalClosed", "true");
      window.location.href = "/";
      setModalOpen(false);
      return () => clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [navigate]);
  return (
    <CustomModal open={modalOpen}>
      <div className="linkedin-company-modal-box">
        <div
          className="linkedin-company-modal-header-img"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img src="image/others/linkedIn-connect-company.svg" alt="linkedin" />
        </div>
        <div className="linkedin-company-modal-profile-box">
          <div className="linkedin-company-modal-profile-stack">
            <ProfilePicture
              getImageUrl={getImageUrl}
              profilePicturePath={
                linkedInConnected?.linkedinConnectData[0]?.logoPath ||
                profilePicturePath
              }
              cdnUrl={cdnUrl}
              linkedinTrue={false}
              avatarStyle={{ width: "56px", height: "56px" }}
            />
            {linkedInConnected?.linkedinConnectData
              ?.slice(1)
              .map((account, index) => (
                <div
                  style={{
                    position: "absolute",
                    left: `${(index + 1) * 40}px`,
                  }}
                  key={index}
                  onClick={() => {
                    console.log(linkedInConnected?.linkedinConnectData, "data");
                    console.log(account, "account");
                    console.log(account?.logoPath, "logoPath");
                    console.log(
                      account?.profilePicturePath,
                      "profilePicturePath"
                    );
                    console.log(
                      account?.logoPath || account?.profilePicturePath,
                      "OR"
                    );
                  }}
                >
                  <ProfilePicture
                    getImageUrl={getImageUrl}
                    profilePicturePath={
                      account?.logoPath || account?.profilePicturePath
                    }
                    cdnUrl={cdnUrl}
                    linkedinTrue={false}
                    avatarStyle={{ width: "56px", height: "56px" }}
                  />
                </div>
              ))}
          </div>
          <div
            style={{
              marginLeft: `${
                linkedInConnected?.linkedinConnectData?.length * 20
              }px`,
              marginTop: "10px",
            }}
          >
            <img
              src="/image/others/forward-arrow.svg"
              alt="forward-arrow"
              style={{ height: "20px" }}
            />
          </div>
          <div
            style={{
              marginLeft: "-10px",
              boxShadow: "0px 2px 9px 0px #0000002A",
              borderRadius: "50%",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/image/pricing/join-reachifyme.svg" alt="linkedin" />
          </div>
        </div>
        <div
          className="linkedin-company-modal-footer-text-box-mobile"
          style={{}}
        >
          <div>Your accounts have been successfully</div>
          <div> connected to ReachifyMe</div>
        </div>
        <div className="linkedin-company-modal-footer-text-box" style={{}}>
          <div> Your accounts are connected.</div>
          <div>Switch accounts from the top right drop down menu.</div>
        </div>
        <div>
          <div className="linkedin-company-modal-footer-redirection-text">
            Redirecting in
          </div>
          <div className="linkedin-company-modal-footer-redirection-timer">
            {countdown} sec
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default LinkedInConnectedModal;
