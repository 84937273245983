import React, { useEffect, useRef } from "react";

const useOutsideClickEvent = (handler) => {
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return { ref };
};

export default useOutsideClickEvent;
