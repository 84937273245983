import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  zIndex: 1000,
};

const mobStlye = {
  position: "absolute",
  bottom: "0px",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  boxShadow: 24,
  p: 2,
  pb: 4,
  width: "100%",
  zIndex: 1000,
};

const CustomModal = ({
  styles,
  open,
  setOpen,
  maxWidth,
  minWidth,
  width,
  children,
  showCancelButton = false,
  cancelButtonOutside = false,
  setTimeWhileClosing,
  showMobileBar = true,
  showMobileCross = false,
  useCancelAsAbsolute = false,
}) => {
  const isMobile = useSelector((state) => state.device?.isMobileDevice);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={
            isMobile
              ? { ...mobStlye, ...styles }
              : { ...style, ...styles, maxWidth: maxWidth, width: width,minWidth:minWidth }
          }
        >
          {!useCancelAsAbsolute && showCancelButton && (
            <div
              style={{
                display: isMobile ? "none" : "flex",
                justifyContent: "right",
                width: "100%",
                marginBottom: "12px",
                marginRight: "-24px",
                marginTop: "-16px",
              }}
            >
              <img
                src="image/icons/clearBlack.svg"
                alt="close"
                onClick={() => setOpen(false)}
                style={{ cursor: "pointer", marginRight: "-16px" }}
              />
            </div>
          )}
          {useCancelAsAbsolute && showCancelButton && (
            <div
              style={{
                position: isMobile ? "initial" : "absolute",
                display: isMobile ? "flex" : "initial",
                justifyContent: isMobile ? "flex-end" : "normal",
                right: "22px",
                paddingRight: isMobile ? "16px" : "auto",
                marginTop: isMobile ? "24px" : "auto",
                paddingBottom: isMobile ? "4px" : "auto",
                top: isMobile ? "0px" : "auto",
              }}
            >
              <img
                src="image/icons/clearGrey.svg"
                alt="close"
                onClick={() => {
                  setTimeWhileClosing && setTimeWhileClosing();
                  setOpen(false);
                }}
                style={{
                  cursor: "pointer",
                  width: isMobile && showMobileCross ? "12px" : "16px",
                  height: isMobile && showMobileCross ? "12px" : "16px",
                }}
              />
            </div>
          )}
          {showMobileBar && (
            <div
              style={{
                display: isMobile ? "flex" : "none",
                justifyContent: "center",
                width: "100%",
                marginBottom: "16px",
              }}
            >
              <div
                style={{
                  width: "80px",
                  background: "#D1D5DB",
                  height: "4px",
                  borderRadius: "10px",
                }}
              ></div>
            </div>
          )}
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
export default CustomModal;
