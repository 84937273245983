const THUNDER_URL = "/image/others/thunder-home.svg";
const THUMBS_URL = "/image/others/thumbs-home.svg";
const CURATED_URL = "/image/others/curated-home.svg";
const OWN_IDEA_URL = "/image/others/own-idea.svg";
const YOUTUBE_URL = "/image/others/youtube.svg";
const ARTICLE_URL = "/image/others/generate-article.svg";
const BOOK_DEMO = "/image/icons/book-demo.svg";
const VIDEO_PRODUCT_EXPLAINER = "/image/icons/video-product-explainer.svg";



export const demoIdKeyword = "demo";

export const demoCallUrl = "https://calendly.com/devesh-reachifyme/30min";
export const videoProductExplainerKeyword = "video";

export const videoDempoOptions = [
  {
    text: "View product explainer",
    icon: VIDEO_PRODUCT_EXPLAINER,
    url: "",
    id:"video"
  },
  {
    text: "Book a demo call",
    icon: BOOK_DEMO,
    url: "https://calendly.com/devesh-reachifyme/30min",
    id:"demo"
  },
];

export const featuresDisplayContent = [
  {
    img: THUNDER_URL,
    text: "Trending News",
    altText: "Trending News Icon",
    url: "/inspiration/trendingNews",
  },
  {
    img: THUMBS_URL,
    text: "Recommended Ideas",
    altText: "Recommended Ideas Icon",
    url: "/inspiration/recommendedIdeas",
  },
  {
    img: CURATED_URL,
    text: "Curated Posts",
    altText: "Curated Posts Icon",
    url: "/inspiration/curatedPosts",
  },
];

export const inputTypeContent = [
  {
    label: "Your Idea",
    mobileLabel: "Idea",
    ind: 0,
    img: OWN_IDEA_URL,
    altText: "Own Idea Icon",
    identifier: "idea",
  },

  {
    label: "Public Article Link",
    mobileLabel: "Article",
    ind: 1,
    img: ARTICLE_URL,
    altText: "Article Icon",
    identifier: "article",
  },
  {
    label: "YouTube Video",
    mobileLabel: "YouTube",
    ind: 2,
    img: YOUTUBE_URL,
    altText: "Youtube Icon",
    identifier: "youtube",
  },
];

export const inputTabIdea = "idea";
export const inputTabArticle = "article";
export const inputTabYoutube = "youtube";
