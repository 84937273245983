import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Toaster.scss";
import useOutsideClickEvent from "../../../customHooks/useOutsideClickEvent";
import { hideToaster } from "../../../reducers/slices/toastSlice";
import { Close } from "@mui/icons-material";
import { bells, giftBox } from "../../lifetimeAccess/NavbarCard";

const ReachifymeToaster = () => {
  const dispatch = useDispatch();
  const timeoutId = useRef(null);
  const { ref } = useOutsideClickEvent(() => {
    dispatch(hideToaster());
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  });
  const toast = useSelector((state) => state.toast);

  useEffect(() => {
    if (toast.showToast) {
      timeoutId.current = setTimeout(() => {
        dispatch(hideToaster());
      }, 4000);
    }
  }, [toast.showToast]);

  const renderUI = () => {
    switch (toast.style) {
      case "LIFETIME_ACCESS":
        return (
          <LifetimeAccessUI
            ref={ref}
            toast={toast}
            dispatch={dispatch}
            timeoutId={timeoutId}
          />
        );
      case "MAIN":
        return (
          <DeafualtUI
            ref={ref}
            toast={toast}
            dispatch={dispatch}
            timeoutId={timeoutId}
          />
        );
      default:
        return (
          <DeafualtUI
            ref={ref}
            toast={toast}
            dispatch={dispatch}
            timeoutId={timeoutId}
          />
        );
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        width: "100%",
      }}
    >
      <div
        style={{
          postion: "relative",
          width: "100%",
        }}
      >
        {toast.showToast && renderUI()}
      </div>
    </div>
  );
};

export default ReachifymeToaster;

const DeafualtUI = ({ ref, toast, dispatch, timeoutId }) => {
  return (
    <div
      ref={ref}
      style={{
        width: "fit-content",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%) translateY(80px)",
        backgroundColor: "#f3b420",
        border: "1px solid #f3b420",
        color: "#121212",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: "500",
        textAlign: "center",
        padding: "12px 24px",
        marginBottom: "6px",
        borderRadius: "60px",
        zIndex: "10000",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        animation: "0.4s ease-in-out toast-in",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div
          style={{
            // flexGrow: "1",
            width: "fit-content",
            minWidth: "240px",
          }}
        >
          {toast.message}
        </div>

        <Close
          onClick={() => {
            dispatch(hideToaster());
            if (timeoutId.current) {
              clearTimeout(timeoutId.current);
            }
          }}
          sx={{
            width: "20px",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

const LifetimeAccessUI = ({ ref, toast, dispatch, timeoutId }) => {
  const isMobile = useSelector((state) => state.device?.isMobileDevice);
  return (
    <div
      ref={ref}
      style={{
        width: isMobile ? "100vw" : "fit-content",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%) translateY(80px)",
        backgroundColor: "#6366F1",
        color: "#121212",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: "500",
        textAlign: "center",
        padding: isMobile ? "0px 12px 12px 12px" : "0px 18px 12px 18px",
        marginBottom: "6px",
        borderRadius: isMobile ? "0px" : "80px",
        zIndex: "100000",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        animation: "0.4s ease-in-out toast-in",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          gap: isMobile ? "6px" : "10px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <img src={bells} alt="bell" />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: isMobile ? "12px" : "16px",
              fontWeight: "500",
              color: "#FFFFFF",
            }}
          >
            Lifetime access is requested, Our executives will
            <br />
            reach out to you soon by{" "}
            <b style={{ color: "inherit" }}>Email or LinkedIn</b>
          </div>
        </div>
        <div>
          <img src={giftBox} alt="gift-box" />
        </div>
      </div>
    </div>
  );
};
