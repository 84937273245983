import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import WelcomeMessage from "./Components/WelcomeMessage";
import { useSelector } from "react-redux";
import Buttons from "../custombuttons/Buttons";
import LinkedInConnectModal from "../linkedinConnection/LinkedInConnectModal";
import PreferencesModal from "../../components/settingsPage/components/PreferencesModal";
import ActiveDot from "./Components/ActiveDot";
import LinkedInConnectedModal from "../linkedinConnection/LinkedInConnectedModal";
import VideoOnBoarding from "../videoOnBoarding";
import VideoOnBoardingBody from "../videoOnBoarding/component/VideoOnBoardingBody";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  zIndex: 1000,
  width: 1000,
  height: 681,
  "@media (max-height: 701px)": {
    height: 454,
  },
};

const mobStlye = {
  position: "absolute",
  bottom: "0px",
  bgcolor: "background.paper",
  background:
    "linear-gradient(178.6deg, #FDF9D7 -0.01%, rgba(255, 255, 255, 0) 34.24%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  border: "none",
  outline: "none",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  boxShadow: 24,
  width: "100%",
  zIndex: 1000,
  p: 0,
  height: "80%",
};

const BottomButtonContainer = ({
  btnText,
  onBoardingStatus,
  setOnBoardingStatus,
}) => {
  const isMobile = useSelector((state) => state.device?.isMobileDevice);
  const handleLetsGoClick = () => {
    if (onBoardingStatus === 0) {
      setOnBoardingStatus(1);
      localStorage.setItem("onboarding-status", 1);
    }
  };
  return (
    <div
      style={{
        padding: isMobile ? "0px" : "0px 20px",
        position: isMobile ? "" : "absolute",
        bottom: "12px",
        right: "0px",
        width: "100%",
      }}
    >
      {onBoardingStatus === 0 && (
        <div className="video-on-boarding-bottom-btn-container">
          <div className="video-on-boarding-active-dot-container">
            <ActiveDot active={0} />
          </div>
          <div className="btn-container" style={{ flex: 1 }}>
            <div>
              <Buttons
                text={btnText}
                variant={"primary"}
                styles={{ height: isMobile ? "40px" : "50px" }}
                clickHandler={handleLetsGoClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CombinedModalForOnboarding = ({
  open,
  handleClose,
  onBoardingStatus,
  setOnBoardingStatus,
}) => {
  //states from the redux selector
  const isMobile = useSelector((state) => state.device?.isMobileDevice);
  const [showConnectedModal, setShowConnectedModal] = React.useState(false);
  const [isVideoPlay, setIsVideoPlay] = React.useState(false);
  const linkedinConnect = useSelector(
    (state) => state.linkedinConnect?.linkedinConnectData,
  );
  useEffect(() => {
    let timer;
    if (onBoardingStatus === 0) {
      timer = setTimeout(() => {
        setIsVideoPlay(true);
      }, 5000);
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [onBoardingStatus]);
  //useEffect to handle change the onboarding status after 10 seconds if the onBoardingStatus is 0
  // useEffect(() => {
  //   let timer;
  //   if (onBoardingStatus === 0) {
  //     timer = setTimeout(() => {
  //       setOnBoardingStatus(1);
  //       localStorage.setItem("onboarding-status", 1);
  //     }, 8000000);
  //   } else {
  //     clearTimeout(timer);
  //   }
  //   return () => clearTimeout(timer);
  // }, [onBoardingStatus]);

  return showConnectedModal && linkedinConnect.length > 1 ? (
    <LinkedInConnectedModal />
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      //   onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      BackdropProps={{
        timeout: 500,
        style: {
          backdropFilter: "blur(8px)", // Apply backdrop filter for blur effect
        },
      }}
    >
      <Fade in={open}>
        <Box sx={isMobile ? mobStlye : { ...style }}>
          {onBoardingStatus === 0 ? (
            <Box
              sx={{
                overflowY: "scroll",
                " &::-webkit-scrollbar": {
                  display: "none",
                },
                height: "100%",
                padding: "24px",
              }}
            >
              <VideoOnBoardingBody
                userName={"user_name"}
                isVideoPlay={isVideoPlay}
              />
              <BottomButtonContainer
                btnText={"Next Step"}
                onBoardingStatus={onBoardingStatus}
                setOnBoardingStatus={setOnBoardingStatus}
              />
            </Box>
          ) : onBoardingStatus === 1 ? (
            <Box
              sx={{
                overflowY: "scroll",
                " &::-webkit-scrollbar": {
                  display: "none",
                },
                height: "100%",
                padding: "24px",
              }}
            >
              <PreferencesModal
                open={true}
                btnText={"Setup later"}
                btnMainTxt={"Next Step"}
                handlePrevious={() => {
                  setOnBoardingStatus(0);
                  localStorage.setItem("onboarding-status", 0);
                }}
                handleClose={() => {
                  setOnBoardingStatus(2);
                  localStorage.setItem("onboarding-status", 2);
                }}
              />
              <BottomButtonContainer
                btnText={"Next Step"}
                onBoardingStatus={onBoardingStatus}
                setOnBoardingStatus={setOnBoardingStatus}
              />
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingBottom: "24px",
                paddingTop: !isMobile && "24px",
              }}
            >
              <LinkedInConnectModal
                setShowConnectedModal={setShowConnectedModal}
                btnBackText={"Back"}
                handlePrevious={() => {
                  setOnBoardingStatus(1);
                  localStorage.setItem("onboarding-status", 1);
                }}
              />
              <BottomButtonContainer
                btnText={"Next Step"}
                onBoardingStatus={onBoardingStatus}
                setOnBoardingStatus={setOnBoardingStatus}
              />
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CombinedModalForOnboarding;
