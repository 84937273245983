import React from 'react';
import { useSelector } from 'react-redux';

const ActiveDot = ({ active }) => {
    //useSelector to get mobile device status
    const isMobile = useSelector((state) => state.device?.isMobileDevice);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {[...Array(3)].map((_, index) => (
                <div
                    key={index}
                    style={{
                        width: isMobile ? '8px' : '10px',
                        height: isMobile ? '8px' : '10px',
                        borderRadius: '50%',
                        backgroundColor: index === active ? '#6366F1' : '#D9D9D9',
                        margin: '0 3px',
                    }}
                ></div>
            ))}
        </div>
    )
}

export default ActiveDot;
