import React from "react";
import bells from "./assets/bells.svg";
import giftBox from "./assets/gift-box.svg";

const NavbarCard = ({ setIsModalOpen, price }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "#003420",
        display: "flex",
        gap: "16px",
      }}
    >
      <div>
        <img src={bells} alt="bell" />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "calc(100% - 380px)",
        }}
      >
        <div
          onClick={() => {
            setIsModalOpen(true);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontWeight: "300",
              color: "#EFF1F5",
            }}
          >
            Special New Year Gift, {""}
          </span>
          <div
            style={{
              display: "inline-block",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: "16px",
                fontWeight: "600",
                textDecorationStyle: "solid",
                textDecorationThickness: "20%",
                textDecorationSkipInk: "none",
                background: "#ffc700",
                color: "transparent",
                backgroundClip: "text",
                textDecoration: "underline",
              }}
            >
              Get Lifetime Access
            </div>
            <div
              style={{
                marginTop: "-2px",
                width: "100%",
                height: "2px",
                borderRadius: "2px",
                background: "#ffc700",
              }}
            ></div>
          </div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "300",
              color: "#EFF1F5",
            }}
          >
            {" "}
            to all our features for
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "300",
              color: "#EFF1F5",
              fontFamily: "sans-serif",
            }}
          >
            {" "}
            {price}{" "}
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "300",
              color: "#EFF1F5",
            }}
          >
            only! - valid till 26th Jan
          </span>
        </div>
      </div>
      <div>
        <img src={giftBox} alt="gift-box" />
      </div>
    </div>
  );
};

export default NavbarCard;
export { bells, giftBox };
