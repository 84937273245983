import React from "react";
import PreferencesModal from "../../../components/settingsPage/components/PreferencesModal";

const PreferencesBody = () => {
  return <div>PreferencesBody

    <PreferencesModal open={true} isButton={false} btnMainTxt="Next Step"  btnText={"Set up later"}/>
  </div>;
};

export default PreferencesBody;
