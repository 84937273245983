import React from "react";
import {
  videoOnBoardingDesktopUrl,
  videoOnBoardingMobileUrl,
  welcomeText,
} from "../utils/constant";
import AllMethodsLogics from "../logics/AllMethodsLogics";
import "../styles/VideoOnBoardingBody.scss";
import { useSelector } from "react-redux";

const VideoOnBoardingBody = ({ userName = "user_name" }) => {
  const { greetingMsgUserName } = AllMethodsLogics();
  const isMobile = useSelector((state) => state.device?.isMobileDevice);

  return (
    <div style={{ height: "88%" }}>
      <div className="video-on-boarding-welcome-text">{welcomeText}</div>
      <div className="video-on-boarding-video-container">
        <video
          className="video-on-boarding-video"
          poster={
            isMobile
              ? "/image/video-product-explainer-mobile-thumbnail.svg"
              : "/image/video-product-explainer-thumbnail.svg"
          }
          src={isMobile ? videoOnBoardingMobileUrl : videoOnBoardingDesktopUrl}
          controls={true}
          style={{ borderRadius: "12px" }}
        />
      </div>
    </div>
  );
};

export default VideoOnBoardingBody;
