import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import AppRouter from "./AppRouter";
import "./global.scss";
import "./v2/styles/index.ts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Interceptor from "./axios/interceptors";
import CombinedModalForOnboarding from "./sharedcomponents/CombinedModalForOnboarding";
import ReachifymeToaster from "./sharedcomponents/others/Toaster/ReachifymeToaster";

Interceptor.interceptor(store);

function App() {
  return (
    <Provider store={store}>
      <>
        <AppRouter />
        <ReachifymeToaster />
        <ToastContainer />
      </>
    </Provider>
  );
}

export default App;
